.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    box-shadow: 0px 4px 18px 0px #00000014;
    padding: 20px 55px;

}

.header-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header .name {
    font-family: 'GothamSSm';
    font-size: 29px;
    font-weight: 500;
    line-height: 34.81px;
    margin-left: 39px;
    color: #01A7C2;
}

.burger-menu {
    display: none;
}


@media screen and (max-width: 1100px) {
    .header {
        padding: 15px;
    }

    .header-logo {
        display: none;
    }

    .burger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 15px 0 15px 0;
    }

    .burger-menu div {
        width: 40px;
        height: 3px;
        background-color: #01A7C2;
        transition: transform 0.2s ease, opacity 0.2s ease;
    }
    
    .burger-menu.open div:nth-child(1) {
        transform: translateY(12px) rotate(45deg);
    }
    
    .burger-menu.open div:nth-child(2) {
        opacity: 0;
    }
    
    .burger-menu.open div:nth-child(3) {
        transform: translateY(-12px) rotate(-45deg);
    }
}