.account-content {
    position: relative;
    width: 1133px;
    height: 100%;
    padding: 56px 56px;
    overflow-y: auto;
    scrollbar-width: none;
}

.company-data {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 87px;
}

.logo {
    width: 101px;
    height: 101px;
    background-color: #01A7C2;
    border-radius: 100%;
    margin-right: 40px;
}

.company-data-info {
    display: flex;
    flex-direction: column;
}

.company-data-info span{
    margin-bottom: 8px;
}

.user-name {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

.company-name {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
}

.company-city {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    text-align: left;
    color: #686B7C;
}

.input-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comp input {
    width: 957px;

}

.input-info img {
    cursor: pointer;
    display: flex;
    align-self: baseline;
    margin: 16px;
}


.city-dropdown, .suggestions-list {
    position: absolute;
    top: 80px;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
  
  .city-option, .suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .city-option:hover, .suggestion-item:hover {
    background-color: #f0f0f0;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo span {
    color: white;
    font-size: 36px;
  }

  .company-inputs {
    height: 100%;
  }

  @media screen and (max-width: 1100px) {
    .company-inputs {
        padding-bottom: 100px;
    }

    .account-content {
        width: 100%;
        padding: 15px;
    }
}