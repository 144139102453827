.balance-wrapper {
    display: flex;
    align-items: center;
}

.ammount {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

}

.counter {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.ammount span {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: right;
    color: #686B7C;
}

.add-balance-button {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01A7C2;
    border-radius: 100%;
}

.add-balance-button:hover {
    cursor: pointer;
}