.address {
    position: relative;
}

.suggestions-dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
    /* Исправлено позиционирование для выравнивания снизу */
    top: 100%;
    left: 0;
    margin-top: 2px; /* Небольшой отступ от поля ввода */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Тень для лучшего отображения */
}

.suggestion-option {
    padding: 8px;
    cursor: pointer;
}

.suggestion-option:hover {
    background-color: #f0f0f0;
}

.input-error {
    border-color: red;
  }