.content-container {
  display: flex;
  overflow: auto;
}

.content-wrapper {
  min-width: 829px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 50px 0px 50px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 1900px) {
  .content-container {
    margin: 0 auto;
    width: 900px;
    position: relative;
  }
}

@media screen and (max-width: 1100px) {
  .content-container {
    width: 100%;
  }

  .content-wrapper {
    min-width: auto;
    width: 100%;
    padding: 15px;
  }
}


