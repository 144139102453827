.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 315px;
    height: 100%;
    padding: 21px 0 32px 32px;
    background: #F5F5F5;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
}

.logout-button,
.navbar a {
    display:flex;
    align-items: center;
    padding: 16px;
}

.logout-button a {
    padding: 0;
}

.navbar li.active,
.navbar a:hover {
    cursor: pointer;
    border-radius: 100px 0px 0px 100px;
    background: #FFFFFF;

}

.logout-button:hover {
    cursor: pointer;
    border-radius: 100px 0px 0px 100px;
    background: #FFFFFF;
}

.logout-button img,
.navbar img {
    margin-right: 16px;
}

@media screen and (max-width: 1100px) {
    .mobile {
        position: absolute;
        transform: translateX(-100%);
        transition: 0.3s;
        z-index: 2;
    }

    .mobile.open {
        transform: translateX(0);
    }
}