.create-add-button {
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 100px;
    border: 1px solid #01A7C2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    margin-bottom: 20px;
}

.create-add-button:hover {
    color: #fff;
    background-color: #01A7C2;
}

.ads {
    position: relative;
}

.ad {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #01A7C2;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ad:hover {
    transform: scale(1.02); /* Увеличение элемента при наведении */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Добавление тени при наведении */
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ad .description > div {
    display: flex;
    margin-bottom: 10px;
}

.description img {
    margin-right: 6px;
}

.ad-name {
    margin-right: 10px;
}

.ad-status {
    color: #01A7C2;
}

.ad-price {
    margin-right: 30px;
}

.ad-addres, .ad-metro {
    font-size: 12px;
    font-weight: 300;
    color: #686B7C;
}

.archive {
    color: red;
}


@media screen and (max-width: 1100px) {
    .ad {
        flex-direction: column;
    }

    .buttons .button {
        width: 100%;
        margin-bottom: 10px;
    }

    .ad .description div {
        display: flex;
        justify-content: space-between;
    }
}



