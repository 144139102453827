* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Noto+Sans+Telugu:wght@100..900&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "GothamSSm";
  src: url('./assets/fonts/GothamSSm-Medium.woff2') format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GothamSSm";
  src: url('./assets/fonts/GothamSSm-Book.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
}


body {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

input, select {
  height: 56px;
  border-radius: 8px;
  border: 1px solid #DDDEE2;
  padding: 16px;
  font-weight: 400;
  font-size: 18px;
}

input:focus, select:focus, textarea:focus {
  outline: none;
}

textarea {
  border: 1px solid #DDDEE2;
  border-radius: 8px;
  padding: 10px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.button {
  position: relative;
  width: 187px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #01A7C2;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.button:hover {
  cursor: pointer;
  background-color: #01A7C2;
  color: #fff;
}

.button.delete {
  border: 1px solid #C55A5A;
}

.button.delete:hover {
  background-color: #C55A5A;
  border: 1px solid #C55A5A;
  color: #fff;
}