.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); /* полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* чтобы прелоадер был поверх содержимого */
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #01A7C2;;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
