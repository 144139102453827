.details-bar-wrapper {
    position: relative;
    width: 825px;
    padding: 36px;
    background: #F5F5F5;
    overflow-y: auto;
    transform: translateX(100%); /* Начальное положение вне экрана */
    transition: transform 0.3s ease; /* Плавный переход */
}

.details-bar-wrapper.open {
    transform: translateX(0);
}

.details-bar-wrapper select {
    cursor: pointer;
    background-color: #fff;

}

.details-bar-wrapper label {
    font-size: 18px;
    color: #686B7C;
}

.details-bar-wrapper select:focus {
    list-style-type: none;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
}

.row > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.long {
    position: relative;
    width: 100%;
}

.short {
    width: 340px;
}
  
.create-button {
    width: 100%;
    background-color: #fff;
}

.details-bar-back-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
}

.details-bar-back-button img {
    cursor: pointer;
}

.details-bar-back-button span {
    font-size: 14px;
    color: #686B7C;
}

.details-bar-wrapper .buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.details-bar-wrapper .button {
    background-color: #fff;
}

.details-bar-wrapper .button:hover {
    background-color: #01A7C2;
}

.picker-input {
    width: 100%;
}

@media screen and (max-width: 1900px) {
    .details-bar-wrapper {
        position: absolute;
        width: 825px;
        height: 100%;
        background: #F5F5F5;
        transform: translateY(-100%); /* Начальное положение вне экрана */
        opacity: 0; /* Начальная непрозрачность */
        visibility: hidden; /* Начальная невидимость */
        transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease; /* Плавный переход */
    }

    .details-bar-wrapper.open {
        transform: translateY(0);
        opacity: 1; /* Прозрачный */
        visibility: visible; /* Видимый */
    }
}

@media screen and (max-width: 1100px) {
    .details-bar-wrapper {
        width: 100%;
        padding: 15px;
    }

    .row {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .row > div {
        margin-bottom: 10px;
    }

    .short {
        width: 100%;
    }
}