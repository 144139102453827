@font-face {
    font-family: "GothamSSm";
    src: url('../../assets//fonts/GothamSSm-Medium.woff2') format("woff2");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "GothamSSm";
    src: url('../../assets//fonts/GothamSSm-Book.woff2') format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  

.login-wrapper {
    width: 100%;
}

.header {
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 4px 18px 0px #00000014;
    padding: 20px 55px;

}

.header .name {
    font-family: 'GothamSSm';
    font-size: 29px;
    font-weight: 500;
    line-height: 34.81px;
    margin-left: 39px;
    color: #01A7C2;
}

.login-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
}

.brand {
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.brand img {
    height: 102px;
}

.brand span {
    font-family: 'GothamSSm';
    font-size: 64px;
    font-weight: 400;
    line-height: 34.81px;
    margin-left: 10px;
    color: #01A7C2;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form h3 {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    margin-bottom: 46px;
}

.login-form label {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    color: #686B7C;
    margin-bottom: 6px;
}

.login-form input {
    text-align: center;
    width: 237px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #DDDEE2;
    margin-bottom: 21px;
    padding: 16px 20px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    text-align: left;
}

.btn {
    cursor: pointer;
    border: 1px solid #01A7C2;
    min-width: 114px;
    height: 32px;
    padding: 8px 12px 8px 12px;
    border-radius: 100px;
    background-color: #fff;
}

.code {
    text-align: center !important;
}

.timer {
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 100;
}