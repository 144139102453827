.tab-controller {
    display: flex;
    position: relative;
    width: 100%;
    height: 32px;
    border-radius: 100px;
    border: 1px solid #01A7C2;
    align-items: center;
    justify-content: space-between;
    background-color: #01A7C2;
    margin-bottom: 20px;
}

.tab {
    cursor: pointer;
    width: 50%;
    text-align: center;
    color: #fff;
}


.bubble {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: #fff; /* Цвет пузыря */
    border-radius: 20px;
    transition: transform 0.3s ease-in-out; /* Анимация перекатывания */  
}

.bubble.left {
    transform: translateX(0);
}
  
.bubble.right {
    transform: translateX(100%);
}