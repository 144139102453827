.popup-content textarea {
    width: 100%;
    height: 189px;
    resize: none;
    margin-bottom: 22px;
}

.comment {
    width: 487px !important;
}

.comment .buttons {
    flex-direction: row;
}

@media screen and (max-width: 1100px) {
    .comment {
        width: 100% !important;
    }
}