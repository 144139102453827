.responses {
    position: relative;
}

.response {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #01A7C2;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.response>div:first-of-type {
    display: flex;
    justify-content: space-between;
}

.response:hover {
    transform: scale(1.02); /* Увеличение элемента при наведении */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Добавление тени при наведении */
}
.personal-data > div {
    margin-bottom: 3px;
}

.personal, .experience {
    display: flex;
    color: #686B7C;
    font-size: 14px;
}

.response .nation {
    margin-right: 13px;
}

.name {
    font-weight: 400;
    font-size: 20px;
}

.contact {
    display: flex;
    align-items: center;
}

.phone {
    margin-right: 15px;
    font-size: 20px;
}

.ad-id {
    background-color: #01A7C2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: 50px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
}

.add-comment-btn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    color: #01A7C2;
}

.ad-comment-block {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background-color: #F1F1F1;
    color: #686B7C;
    padding: 10px;

}


@media screen and (max-width: 1100px) {

    .response>div:first-of-type {
        flex-direction: column;
    }

    .personal-data > div {
        margin-bottom: 10px;
    }
}

