.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 376px;
    max-width: 90%;
    min-height: 201px;
    display: flex;
    flex-direction: column;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .error-msg {
    margin-bottom: 20px;
  }

  .popup-content label{
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: 400;
    line-height: 29.3px;
    margin-bottom: 20px;
  }

  .popup-content input{
    margin-bottom: 20px;
  }

  .error-msg {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: 400;
    line-height: 29.3px;
    text-align: left;
    color: #FF3B30;
  }