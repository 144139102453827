.received {
    background-color: #01A7C2;
    color: #fff;
}

.added {
    background-color: #C55A5A;
    border: 1px solid #C55A5A;
    color: #fff;
}

.added:hover {
    background-color: #C55A5A;
    border: 1px solid #C55A5A;
    color: #fff;
}

@media screen and (max-width: 1100px) {
    .contact {
        flex-direction: column;
        justify-content: center;
    }

    .contact div {
        width: 100%;
        margin-bottom: 10px;
    }

    .phone {
        margin: 0;
        text-align: center;
    }
}

